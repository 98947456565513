import React, { forwardRef, useContext, useEffect, useState } from 'react'
import './TooltipImport.scss'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { ModalContext } from '@root-gipro/context/modal-context'
import { form20Text, form2Text, importNotes } from '@root-gipro/modules/userProjects/utils/helpers'

const ImportButton = ({ onClick, children }: { onClick: (e: React.MouseEvent) => void; children: React.ReactNode }) => (
	<SecondaryBtn data-popup='import_form_modal' onClick={onClick} className='add_btn_tooltip_import'>
		{children}
	</SecondaryBtn>
)

interface ITooltipImport {
	open: boolean
	setTypeImport: (mode: string) => void
	setOpenHelpWindow: (mode: boolean) => void
}

const TooltipImport = forwardRef<SVGSVGElement, ITooltipImport>(({ open, setTypeImport, setOpenHelpWindow }, ref) => {
	const { setAnchorEl } = useContext(ModalContext)

	if (!open || !ref || !('current' in ref) || !ref.current) return null

	const handleImportClick = (type: string) => (e: React.MouseEvent<Element>) => {
		setAnchorEl!(e.currentTarget as HTMLElement)
		setTypeImport(type)
		setOpenHelpWindow(false)
	}

	return (
		<div
			className='help-window'
			style={{
				position: 'absolute',
				top: 89,
				left: 430,
				zIndex: 9999,
			}}
		>
			<div className='help-window-content'>
				<h3>Инструкция по импорту форм</h3>
				<Section title='Импорт Ф2' text={form2Text} />
				<Section title='Импорт Ф20' text={form20Text} />

				{/* Видео-контент */}
				<div className='tooltip_video_content'>
					<hr className='divider' />
					<iframe
						src='https://vkvideo.ru/video_ext.php?oid=-211033733&id=456239033&hash=14ba13790e2988f5'
						className='centered-video'
						width='640'
						height='360'
						allow='autoplay; encrypted-media; fullscreen; picture-in-picture'
					></iframe>
					<div className='import_tooltip_buttons'>
						<ImportButton onClick={handleImportClick('import-form-2')}>Импортировать данные из формы 2</ImportButton>
						<ImportButton onClick={handleImportClick('import-form-20')}>Импортировать данные из формы 20</ImportButton>
						<ImportButton onClick={handleImportClick('import-form-new-20')}>
							Импортировать данные из формы 20 (обновленная)
						</ImportButton>
					</div>
					<hr className='divider' />
				</div>
				<p>{importNotes}</p>
			</div>
		</div>
	)
})

// Вспомогательный компонент для разделов
const Section = ({ title, text }: { title: string; text: string }) => (
	<>
		<h4>{title}</h4>
		<p>{text}</p>
	</>
)

export default TooltipImport
