import { IUnc, IUserProject } from '@root-gipro/store/interfaces'

export const createUncArr = (tableNum: number, uncs: IUnc[]) => {
	let arr: IUnc[] = []
	if (uncs && uncs.length > 0) {
		if (tableNum === 6) {
			arr = uncs
		} else {
			arr = uncs.filter((unc: IUnc) => unc.tableNum === tableNum)
		}
	}

	return arr
}

export function addActiveUnc(activeUncs: IUnc[], unc: IUnc) {
	const index = activeUncs.findIndex(active => active.id === unc.id),
		clone = activeUncs.slice()
	if (index === -1) {
		clone.push(unc)
	} else {
		clone.splice(index, 1)
	}
	return { clone, index }
}

export function getTargetVoltageValues(buffer: any, id: any) {
	const index = buffer.findIndex((item: any) => {
		return item.id == id
	})
	if (index >= 0) {
		return buffer[index]['targetVoltageValues']
	}
}
export const filterWidthWindow = (step: string | undefined) => {
	switch (step) {
		case 'error-list':
			return '768px'
		case 'errors-list':
			return '768px'
		case 'check-list':
			return '240px'
		case 'unNormalizeForm20Instructions':
			return '1232px'
		case 'open-table-form20':
			return '100%'
		default:
			break
	}
}

export const ROSSETI_ID = 10

export const requiredFields: Array<{ field: keyof Omit<IUserProject, 'author'>; message: string }> = [
	{ field: 'code', message: 'поле «Идентификатор»' },
	{ field: 'groupNum', message: 'поле «Номер группы»' },
	{ field: 'name', message: 'поле «Наименование инвестиционного объекта»' },
	{ field: 'regionIds', message: 'поле «Субъект РФ»' },
	{ field: 'unc_sort', message: 'режим УНЦ 2019/2024' },
]

// Тексты контента
export const form2Text = `
Имя файла должно соответствовать формату: [произвольное название]-F2_20.12.2023.xlsx, где F2 - ключевой признак формы 2, 20.12.2023 - дата публикации на сайте Минэнерго.
Структура *.xIsx файла должна соответствовать шаблону формы с сайта Минэнерго.

Шаблон можно скачать из меню «Шаблоны» GIPRO УНЦ.

Название компании в форме должно совпадать с названием компании в БД. Можно проверить в фильтре.

В формулах не должно быть ссылок на связанные источники данных (другие листы или файлы).

Нужно загружать формы, содержащие только значения.

Если в исходном файле есть формулы, то можно сделать копию файла (учитывать требования к формату имени файла), в каждом листе скопированного файла скопировать все ячейки и вставить их как значения.
`

export const form20Text = `
Имя файла должно соответствовать формату Минэнерго (содержать огрн).

Структура *.xIsx файла должна соответствовать шаблону формы с сайта Минэнерго.

Шаблон можно скачать из меню «Шаблоны» GIPRO УНЦ.

Форма должна содержать корректные наименования и стоимость расценок.
`

export const importNotes = `
Импорт форм можно производить в различных режимах (УНЦ-2019/2024), а также указывать раздел для импорта: 

1. в раздел «ИПР» (доступно только разработчикам и сотрудникам технической поддержки ООО «MЦP»).

2. в раздел «Проекты» с указанной версией ИПР (доступно пользователям конфигураций системы в контуре компании, к которой они относятся).
Необходимо соблюдать последовательность импорта:

1. форма 2

2. форма 20

При импорте формы 20 выполняется проверка формы на наличие технических ошибок. По 
результатам импорта формы на экране отображается отчет об ошибках, который можно скачать во внешний файл. Итоги проверки также записываются в журнал логов, его можно посмотреть вызвав команду слоги» в главном меню раздела «Проекты»`
