export const firstCheckExclude = (object: any) => {
	// todo any
	if (
		!object.noUncs &&
		!object['project.noUncs'] &&
		object.valuationUncOfferForecast !== '' &&
		object['project.valuationUncOfferForecast'] !== 1 &&
		object.estimatedOfferYM !== '' &&
		object['project.estimatedOfferYM'] !== 1
	) {
		return true
	} else {
		return false
	}
}
export const noUncs = (object: any) => {
	// todo any

	if (object.noUncs || object['project.noUncs']) {
		return [
			'project.uncCell.uncThId',
			'uncCell.uncThId',
			'project.uncCell.uncTextParameterId',
			'uncCell.uncTextParameterId',
			'uncCell.voltageValue',
			'project.uncCell.voltageValue',
			'uncCell.uncMainId',
			'project.uncCell.uncMainId',
			'uncCell.number',
			'project.uncCell.number',
			'uncCell.subNumber',
			'project.uncCell.subNumber',
			'unc.count.min',
			'unc.count.max',
			'project.unc.count.max',
			'project.unc.count.min',
			'project.rgStatusId',
		]
	} else return []
}
export const valuationUncOfferForecast = (object: any) => {
	// todo any

	if (object.valuationUncOfferForecast === '' || object['project.valuationUncOfferForecast'] === 1) {
		return [
			'project.valuationUncOfferForecast.min',
			'project.valuationUncOfferForecast.max',
			'valuationUncOfferForecast.min',
			'valuationUncOfferForecast.max',
		]
	} else return []
}
export const estimatedOfferYM = (object: any) => {
	// todo any
	if (object.estimatedOfferYM === '' || object['project.estimatedOfferYM'] === 1) {
		return [
			'project.estimatedOfferYM.min',
			'project.estimatedOfferYM.max',
			'estimatedOfferYM.min',
			'estimatedOfferYM.max',
			'project.estimatedOfferCurrent.max',
			'project.estimatedOfferCurrent.min',
			'estimatedOfferCurrent.min',
			'estimatedOfferCurrent.max',
		]
	} else return []
}
