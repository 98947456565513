import { IInputIntProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import { formatValue } from '@root-gipro/modules/userProjects/utils/formatValue'
import { formatedDeflatorValue, intFormat } from '@root-gipro/utils/helpers/common'
import React, { ReactText, RefObject, useEffect, useState } from 'react'

const InputInt: React.FC<IInputIntProps> = ({
	inputName,
	value,
	changeFunc,
	placeholder,
	disabled,
	precision,
	inputRef,
	setFocusPosition,
}) => {
	const [result, setResult] = useState<ReactText>(value!)

	useEffect(() => {
		if (value) {
			const valWithFixedLength = formatedDeflatorValue(value, precision)

			setResult(valWithFixedLength)
		}
	}, [value])

	return (
		<input
			onFocus={e => {
				const int = e.currentTarget.value
				const val = intFormat(int)
				setResult(val)
			}}
			onChange={e => {
				const int = e.currentTarget.value
				const val = intFormat(int)
				setResult(val)
				setFocusPosition && setFocusPosition(e.currentTarget.selectionStart as number)
			}}
			onBlur={e => {
				const int = e.currentTarget.value
				const val = intFormat(int)
				const valWithFixedLength = formatedDeflatorValue(val, precision)
				setResult(valWithFixedLength)
				changeFunc(int.replace(/\s/g, ''))
			}}
			placeholder={placeholder}
			value={precision ? formatValue(result, precision + 1) : result}
			type='text'
			disabled={disabled}
			className='universal-input'
			id={inputName}
			ref={inputRef as RefObject<HTMLInputElement>}
		/>
	)
}

export { InputInt }
