import React, { useState, useEffect, ChangeEvent } from 'react'
import Autocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	inputBase: {
		'& .MuiOutlinedInput-root': {
			font: 'inherit',
			borderRadius: 4,
			padding: '8px',
			fontSize: '16px',
			border: '1px solid rgb(224, 224, 224)',
			'& fieldset': {
				border: 'none',
			},
			'&:hover fieldset': {
				border: 'none',
			},
			'&.Mui-focused fieldset': {
				border: 'none',
			},
			'&.Mui-error fieldset': {
				borderColor: 'orange',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '8px !important',
			fontSize: '16px',
		},
		'& .MuiOutlinedInput-input::placeholder': {
			fontSize: '16px',
			fontWeight: '400',
			color: 'rgba(0, 0, 0, 0.99)',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'none',
		},
	},
	inputError: {
		'& .MuiOutlinedInput-root': {
			border: '1.5px solid orange',
		},
	},
	listbox: {
		'& .MuiAutocomplete-option': {
			backgroundColor: 'white',
			'&:hover': {
				backgroundColor: '#3f4f5e',
				color: '#FFF',
			},
			'&[aria-selected="true"]': {
				backgroundColor: '#e0e0e0',
			},
		},
	},
})

interface OptionType {
	id: number
	name: string
	description: string
}

interface CustomAutocompleteProps {
	options: OptionType[]
	placeholder?: string
	onValueChange?: (value: OptionType | undefined) => void
	onInputChange?: (value: string) => void
	disabled: boolean
	valueProp?: string | null
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
	options,
	placeholder = 'Введите значение',
	onValueChange,
	onInputChange,
	disabled,
	valueProp,
}) => {
	const classes = useStyles()
	const [value, setValue] = useState<OptionType | undefined>(
		valueProp ? options.find(option => option.name === valueProp) : undefined
	)
	const [inputValue, setInputValue] = useState<string>(valueProp || '')

	const isInvalid = inputValue !== '' && !options.some(option => option.name === inputValue)

	useEffect(() => {
		const newValue = valueProp ? options.find(option => option.name === valueProp) : undefined
		setValue(newValue)
		setInputValue(valueProp || '')
	}, [valueProp, options])

	const handleInputChange = (event: ChangeEvent<{}>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
		const regex = /^[0-9.]*$/
		if (regex.test(newInputValue)) {
			setInputValue(newInputValue)
			if (onInputChange) onInputChange(newInputValue)
		}
	}

	return (
		<Autocomplete
			disabled={disabled}
			freeSolo
			disableClearable // Убирает крестик
			options={options}
			getOptionLabel={option => option.name}
			renderOption={option => (
				<div>
					{option.name} {option.description}
				</div>
			)}
			value={value}
			onChange={(event, newValue: any) => {
				setValue(newValue)
				if (onValueChange) onValueChange(newValue)
			}}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			renderInput={params => (
				<TextField
					{...params}
					variant='outlined'
					placeholder={placeholder}
					className={`${classes.inputBase} ${isInvalid ? classes.inputError : ''}`}
					error={isInvalid}
				/>
			)}
			classes={{
				listbox: classes.listbox,
			}}
		/>
	)
}

export default CustomAutocomplete
