import React, { useState, useEffect } from 'react';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  inputBase: {
    '& .MuiOutlinedInput-root': {
      font: 'inherit',
      borderRadius: 4,
      padding: '8px',
      fontSize: '16px',
      border: '1px solid rgb(224, 224, 224)',
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
      '&.Mui-error fieldset': {
        borderColor: 'orange',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px !important',
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-input::placeholder': {
      fontSize: '16px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.99)',
    },
  },
  inputError: {
    '& .MuiOutlinedInput-root': {
      border: '1.5px solid orange',
    },
  },
  listbox: {
    '& .MuiAutocomplete-option': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#3f4f5e',
        color: '#FFF',
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
});

interface OptionType {
  id: string | number;
  name: string;
  title?: string;
}

interface CustomSelectProps {
    subject?: boolean; // Если требуется
    list: { id: string | number; name: string; title: string }[]; // Соответствие списку
    placeholder?: string; // Текст плейсхолдера
    tooltip?: string; // Тултип
    multy?: boolean; // Множественный выбор (опция для расширения)
    disabled: boolean; // Блокировка компонента
    changeHandler: (value: { id: string | number; name: string }) => void; // Колбэк при изменении
    defaultVal?: { id: string | number; name: string } | null; // Значение по умолчанию
  }
  

  const StageSelect: React.FC<CustomSelectProps> = ({
    subject,
    list,
    placeholder = 'Выберите значение',
    tooltip,
    multy = false,
    disabled,
    changeHandler,
    defaultVal,
  }) => {
    const classes = useStyles();
    const [value, setValue] = useState<any>(defaultVal || null);
    const [inputValue, setInputValue] = useState(value?.id as string || '');
    const [isInvalid, setIsInvalid] = useState<boolean>(true)
  useEffect(()=>{

      const isInvalid = !inputValue ?false : !list.some(item => item.id as string === inputValue);
      setIsInvalid(isInvalid)
  },[inputValue])
    useEffect
    const handleInputChange = (_: any, newInputValue: string) => {
      setInputValue(newInputValue);
  
      // Вызываем changeHandler только если ввод не совпадает с существующими элементами
      if (!list.some(item => item.id as string === newInputValue)) {
        changeHandler({ id: newInputValue, name: newInputValue });
      }
    };
  
    const handleChange = (_: any, newValue: any ) => {
      setValue(newValue);
      setInputValue(newValue?.id || '');
      changeHandler(newValue || { id: '', name: '' }); // Передаём значение в обработчик
    };
  
    useEffect(() => {
   
        setInputValue(()=>defaultVal?.id as string);
        setValue(defaultVal);
   
    }, [defaultVal?.id]);

    return (
      <Autocomplete
        disabled={disabled}
        freeSolo={!multy}
        options={list}
        getOptionLabel={option => option.id}
        renderOption={option =>
            <Tooltip
            title={
              <div
                style={{
                  maxWidth: '300px', 
                  whiteSpace: 'normal',
                  wordWrap: 'break-word', 
                }}
              >
                {option?.title || option.name}
              </div>
            }
            arrow 
          >
            <div  >{option?.name}</div>
            </Tooltip>
        
        
        }
        value={value}
        onChange={handleChange}
        inputValue={inputValue||""}
        onInputChange={handleInputChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            title={tooltip}
            className={`${classes.inputBase} ${isInvalid ? classes.inputError : ''}`}
            error={isInvalid}
          />
        )}
        classes={{
          listbox: classes.listbox,
        }}
      />
    );
  };
  
  export default StageSelect;
  