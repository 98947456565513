import { MenuItem, Select, Tooltip } from '@material-ui/core'
import { addUnc } from '@root-gipro/modules/userProjects/store/actions'
import { IState, IUncAddBuffer, IUncCell } from '@root-gipro/store/interfaces'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './UncAssist.scss'

interface Props {
	uncCellAssist: any
	uncAddBuffer: IUncAddBuffer[]
}

const UncAddAssist: React.FC<Props> = ({ uncCellAssist, uncAddBuffer }) => {
	const { uncsAnalog, uncCells } = useSelector((state: IState) => state.userProjects)
	const { uncMains } = useSelector((state: IState) => state.public)

	const { requiredUncCellIds, duplicateUncCellIds, recommendUncCellIds } = uncCellAssist
	const dispatch = useDispatch()

	const [isShowRequiredMore, setIsShowRequiredMore] = useState(false)
	const [isShowRecommendMore, setIsShowRecommendMore] = useState(false)

	const getFullUnc = (uncID: number | string) => {
		const res = uncCells.find((unc: IUncCell) => unc.id === uncID)
		if (!res?.code) {
			const res = uncMains.find(unc => uncID == unc.id)
			return { code: `${res?.code}. ${res?.name}`, id: res?.id }
		}
		return res
	}

	const getFullTableName = (uncId: number | string) => {
		const uncCell = uncCells.find((unc: IUncCell) => unc.id === uncId)
		const res = uncMains.find(unc => uncCell?.uncMainId == unc.id)
		if (res?.code) {
			return { code: `${res?.code}. ${res?.name}`, id: res?.id }
		}
	}
	const correctRecommendUncs = recommendUncCellIds.map((unc: any) => {
		const uniqueOptions = [...new Set(unc)] as any
		const result = [getFullTableName(uniqueOptions[0])?.id, ...uniqueOptions]
		return result
	})

	const handlAddUnc = (e: any) => {
		if (!correctRecommendUncs.find((itm: any) => e?.target?.value === itm[0])) {
			dispatch(addUnc(uncAddBuffer[0].tableNum, e?.target?.value, uncAddBuffer[0].count, true, uncAddBuffer[0].voltage))
		}
	}
	useEffect(() => {
		if (uncAddBuffer && requiredUncCellIds && uncAddBuffer) {
			dispatch(
				addUnc(uncAddBuffer[0]?.tableNum, requiredUncCellIds[0], uncAddBuffer[0]?.count, true, uncAddBuffer[0]?.voltage)
			)
		}
	}, [])

	return (
		<div className='assist-main'>
			{/* <div className='assist-required-block' onClick={() => setIsShowRequiredMore(!isShowRequiredMore)}>
				<div>Обязательные к добавлению расценки</div>
				<div>
					<svg
						width='10'
						height='6'
						viewBox='0 0 10 6'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						className={isShowRequiredMore ? 'svg-rotate turn' : 'svg-rotate'}
					>
						<path d='M9 1L5 5L1 1' stroke='#3F4F5E' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</div>
			</div> */}
			{isShowRequiredMore && (
				<>
					<div>
						{requiredUncCellIds ? (
							<Select
								labelId='select-label'
								label='Выберите значение'
								placeholder='Выберите значение'
								defaultValue={requiredUncCellIds ? requiredUncCellIds[0] : ''}
								className='select-input required'
								// value={configValue || ''}
								onChange={e => handlAddUnc(e)}

								// onClick={() => setIsOpen(!isOpen)}
							>
								{requiredUncCellIds?.map((id: any) => {
									const unc = getFullUnc(id)
									return (
										<MenuItem key={unc?.id} value={unc?.id} className='required'>
											{unc?.code}
										</MenuItem>
									)
								})}
							</Select>
						) : (
							<div className='required-empty'>Нет обязательных расценок</div>
						)}
					</div>
				</>
			)}
			<div className='assist-block'>
				<div className='assist-block_header' onClick={() => setIsShowRecommendMore(!isShowRecommendMore)}>
					<div>Возможные к добавлению расценки по решению пользователя</div>

					<div>
						<svg
							width='10'
							height='6'
							viewBox='0 0 10 6'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className={isShowRecommendMore ? 'svg-rotate turn' : 'svg-rotate'}
						>
							<path d='M9 1L5 5L1 1' stroke='#3F4F5E' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
						</svg>
					</div>
				</div>
				{isShowRecommendMore && (
					<div className='assist-block_uncs'>
						{correctRecommendUncs ? (
							<>
								{correctRecommendUncs.map((arr: any) => {
									return (
										<Tooltip
											title={getFullUnc(arr[0])?.code as string}
											enterDelay={1000}
											enterNextDelay={2000}
											placement='top'
											key={arr}
										>
											<Select
												labelId='select-label'
												label='Выберите значение'
												placeholder='Выберите значение'
												defaultValue={arr[0]}
												className='select-input possible'
												// value={getFullTableName(arr[0])}
												onChange={e => handlAddUnc(e)}

												// onClick={() => setIsOpen(!isOpen)}
											>
												{arr.map((id: any) => {
													const unc = getFullUnc(id)

													return (
														<MenuItem
															key={unc?.id}
															value={unc?.id}
															className={`possible ${
																correctRecommendUncs.find((itm: any) => unc?.id === itm[0]) && 'dissabled'
															}`}
														>
															{unc?.code}
														</MenuItem>
													)
												})}
											</Select>
										</Tooltip>
									)
								})}
							</>
						) : (
							<div>Нет возможных к добавлению расценок</div>
						)}
					</div>
				)}
			</div>
			<div className='assist-block'>
				<div className='assist-block-title'>Дублирование расценок</div>
				{uncCellAssist?.duplicateUncCellIds ? (
					<div className='assist-block-subtitle'>
						{uncCellAssist?.duplicateUncCellIds.split('\n').map((str: string, i: number) => {
							return <div key={i}>{str}</div>
						})}
					</div>
				) : (
					<div>Нет дублирований</div>
				)}
			</div>
		</div>
	)
}

export default UncAddAssist
