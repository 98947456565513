import React, { useState, ChangeEvent, FormEvent } from 'react'
import { IconButton } from '@material-ui/core'

const HeaderSearch: React.FC = () => {
	const [searchValue, setSearchValue] = useState('')
	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value)
	}
	const searchSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
	}
	const clearSearch = () => setSearchValue('')

	return (
		<form className='header__search' onSubmit={searchSubmit}>
			<input
				type='text'
				className='search'
				placeholder='Поиск'
				value={searchValue}
				onChange={handleSearch}
			/>
			{searchValue && (
				<IconButton type='reset' onClick={clearSearch}>
					<span className='search-clear__btn close-icon' />
				</IconButton>
			)}
			<IconButton type='submit'>
				<span className='search-icon' />
			</IconButton>
		</form>
	)
}

export default HeaderSearch
