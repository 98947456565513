import { Box } from '@material-ui/core'
import { ModalContext } from '@root-gipro/context/modal-context'
import { Justification, ParamsOptions } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/'
import { Detailed } from '@root-gipro/modules/commonProjects/components/SelectProjects/Parameters/Detailed'
import '@root-gipro/modules/commonProjects/styles/Parameters.scss'
import { CardFull, CardItem, CardLeft, CardRight } from '@root-gipro/shared/Card'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import PrikazLinks from '@root-gipro/shared/PrikazLinks/PrikazLinks'
import { IParametersProps } from '@root-gipro/store/interfaces'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { formatDate, prettyCost } from '@root-gipro/utils/helpers/common'
import { copyHrefToClipboard } from '@root-gipro/utils/helpers/copyToClipBoard'
import React, { FC, useContext, useState } from 'react'

const Parameters: FC<IParametersProps> = ({
	editToggleType,
	group,
	project,
	toggleType,
	region,
	downloadCommonProject,
	addToUserProject,
	policesRGCO,
	policesGipro,
	idAddedUserProject,
}) => {
	const [toggler, setToggler] = useState(false)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)

	return (
		<>
			<div className='container-full'>
				<Box marginY={2} display='flex' justifyContent='flex-end'>
					<PrikazLinks uncSort={Number(project.unc_sort)} url={'common-projects'} />
				</Box>
				<CardFull classes='detail-common-projects'>
					<div className='card-full__item'>
						<CardLeft>
							<div className='card-full__header'>
								<div className='card-full__code link-blue'>
									<span className='code'>{project.code}</span>
								</div>
								<div className='card-full__actions'>
									<div
										className='link-blue link card-full__actions-item'
										data-popup='justification'
										onClick={e => setAnchorEl!(e.currentTarget)}
									>
										Обоснование
									</div>
									<span
										data-popup='options'
										onClick={e => setAnchorEl!(e.currentTarget)}
										className='popup-icon card-full__actions-item'
									/>
								</div>
							</div>
							<CardDate>Изменено {formatDate(project.publicationDate)}</CardDate>
							{group && <CardTitle>{group.name}</CardTitle>}
							<CardDescription>{project.name}</CardDescription>
						</CardLeft>
						<CardRight>
							{region && <CardItem title={'Регион'} value={region} />}
							{(project.valuationOfferForecast || project.valuationPlanForecast) && (
								<CardItem
									title={'Оценка полной стоимости в прогнозных ценах'}
									value={
										toggleType === 'offer'
											? addHTML(prettyCost(project.valuationOfferForecast / 10 ** 6))
											: addHTML(prettyCost(project.valuationPlanForecast / 10 ** 6))
									}
								/>
							)}
						</CardRight>
					</div>
				</CardFull>
			</div>
			<Detailed project={project} toggleType={toggleType} editToggleType={editToggleType} />

			{anchorEl?.dataset.popup === 'justification' && (
				<Justification anchorEl={anchorEl!} setAnchorEl={setAnchorEl!} offerRationale={project.offerRationale} />
			)}
			{anchorEl?.dataset.popup === 'options' && (
				<ParamsOptions
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					policesGipro={policesGipro}
					policesRGCO={policesRGCO}
					copyHrefToClipboard={copyHrefToClipboard}
					downloadCommonProject={downloadCommonProject}
					project={project}
					addToUserProject={addToUserProject}
					idAddedUserProject={idAddedUserProject}
					toggler={toggler}
					togglerSubmenu={() => setToggler(!toggler)}
				/>
			)}
		</>
	)
}

export default Parameters
