import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'

interface InfoProps {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
}

const Info: React.FC<InfoProps> = ({ anchorEl, setAnchorEl }) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ width: 360 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			classes='info__drop-down'
		>
			<p className='drop-down__info-txt'>
				Оценка полной стоимости инвестиционного проекта в соответствии с укрупненными нормативами цены типовых
				технологических решений капитального строительства объектов электроэнергетики в прогнозных ценах соответствующих
				лет, млн рублей (с НДС).
			</p>
		</DropDown>
	)
}
export default Info
